import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogEmployeesComponent } from '../dialog-employees/dialog-employees.component';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  toggleMode = false;

  // Toggle Mobile NavBar Side Content
  toggleSideNav(): void {
    if (this.toggleMode === false) {
      this.toggleMode = true;
    } else {
      this.toggleMode = false;
    }
  }

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  dialogEmployees(): void {
    window.scrollTo(0,0);   
    const dialogRef = this.dialog.open(DialogEmployeesComponent, {
      panelClass: 'custom-dialog-container',
      backdropClass: 'darkBackground'
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }
}
