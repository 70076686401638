import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  // Display App Title in Footer
  appTitle = 'Mauzé Painting LLC';

  // Display Current Year in Footer
  year: number = new Date().getFullYear();

  // RouterLinks To Top Of Window
  toTopButton(): void {
    window.scroll(0, 0);
  }

  constructor() {
  }

  ngOnInit(): void {
    // On Scroll Execute ScrollFunction
    // window.onscroll = function() {
    //   scrollFunction();
    // };
    // // Scroll Function
    // function scrollFunction() {
    //   if (document.body.scrollTop > 850 || document.documentElement.scrollTop > 850) {
    //     document.getElementById('ToTopButton').style.display = 'block';
    //   } else {
    //     document.getElementById('ToTopButton').style.display = 'none';
    //   }
    // }
  }
}
