<img id="quote" class="wallpaper" src="/assets/img/photos/wallpaper-top.jpg" alt="wallpaper paint">
<div class="jumbotron-quote">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12">
                <h2>Get a quote in under 24 hrs</h2>
                <h1>Request an online quote</h1>
                <div class="hr"></div>
                <p><b>Or Call: </b><a href="tel:+15173318528‬">(517) 331-8528‬</a></p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <div *ngIf="success">
                    <h1><i class="far fa-paper-plane"></i> Message Sent!</h1>
                    <p>Thank you for your message, <br> We look forward to working with you.</p>
                </div>
                <!-- <h3 *ngIf="name.invalid && name.touched">
                    Please provide your name.
                </h3> -->
                <h3 *ngIf="email.invalid && email.touched">
                    Please provide a valid email.
                </h3>
                <!-- <h3 *ngIf="message.invalid && message.touched">
                    Please provide us with more information here.
                </h3> -->
            </div>
        </div>
        <form [formGroup]="contactForm" [hidden]="success" (ngSubmit)="onSubmit()">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <input [ngClass]="{'error': name.invalid && name.touched }" type="text" placeholder="Your Name" formControlName="name" required>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <input [ngClass]="{'error': email.invalid && email.touched }" type="email" placeholder="Email" formControlName="email" required>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <textarea [ngClass]="{'error': message.invalid && message.touched }" placeholder="Message" formControlName="message" required></textarea>
                    <button [disabled]="contactForm.invalid" color="primary" (click)="openSnackBar('Sending message ...','')">Send Request</button>
                    <div *ngIf="success">
                        <h1><i class="far fa-paper-plane"></i> Message Sent!</h1>
                        <p>Thank you for your message, <br> We look forward to working with you.</p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
