<app-nav></app-nav>

<div class="jumbotron-header">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>About Us</h1>
                <p>We are committed to quality</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-content">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-7 col-md-12 col-sm-12 col-12">
                <h2>About the owner</h2>
                <h1>A Company Committed to Quality from the top down</h1>
                <div class="hr"></div>
                <p>My name is Jeanel Mauzé. I am the owner of Mauzé Painting. I have been professionally painting since 2006. With over 13 years of experience I find that the happiness my clients express to me at the completion of the job and the ability I’ve been given to employ and work with some of the most talented individuals, in the Greater Lansing area and throughout Michigan, brings me an extraordinary amount of personal joy and satisfaction!</p>
            </div>
            <div class="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-12">
                <img src="/assets/img/photos/jeanel-profile.jpg" alt="jeanel mauze profile picture">
            </div>
        </div>
    </div>
</div>

<app-faq></app-faq>

<app-quote></app-quote>

<app-footer></app-footer>