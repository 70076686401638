import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogImagesComponent } from '../dialog-images/dialog-images.component';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  image = '';

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  goTo(location: string): void {
    window.location.hash = '';
    window.location.hash = location;
  }

  imageOne(): void {
    this.image = 'portfolio-one'
    this.openDialog();
  }

  imageTwo(): void {
    this.image = 'portfolio-two'
    this.openDialog();
  }

  imageThree(): void {
    this.image = 'portfolio-three'
    this.openDialog();
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogImagesComponent, {
      data: { image: this.image },
      panelClass: 'custom-dialog-container',
      backdropClass: 'darkBackground'
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

}
