import { NgModule } from '@angular/core';

// ANIMATIONS
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// WEBSITE DIALOG BOX
import { MatDialogModule } from '@angular/material/dialog';

// MATERIAL
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';

// CONTACT FORM
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        MatDialogModule,
        MatToolbarModule,
        MatDividerModule,
        MatExpansionModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        MatSnackBarModule,
        MatTooltipModule
    ],
    exports: [
        BrowserAnimationsModule,
        MatDialogModule,
        MatToolbarModule,
        MatDividerModule,
        MatExpansionModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        MatSnackBarModule,
        MatTooltipModule
    ],
})
export class CustomMaterialModule { }
