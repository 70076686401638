import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-employees',
  templateUrl: './dialog-employees.component.html',
  styleUrls: ['./dialog-employees.component.scss']
})
export class DialogEmployeesComponent implements OnInit {

  accessForm: any;
  errorCode: boolean | any;
  year: number = new Date().getFullYear();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public dialogRef: MatDialogRef<DialogEmployeesComponent>,
  ) { }

  ngOnInit(): void {
    this.accessForm = this.formBuilder.group({
      accessCode: ['', Validators.required],
    });
    // console.log(this.year);
  }

  get accessCode() {
    return this.accessForm.get('accessCode');
  }

  onSubmit(): void {
    if (this.accessCode.value == this.year) {
      this.errorCode = 'true';
      this.router.navigate(['/employee-forms']);
      this.dialogRef.close();
      // console.log(this.errorCode);
    } else {
      this.errorCode = 'false';
      // console.log(this.errorCode);
    }
  }
}
