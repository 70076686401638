<app-nav></app-nav>

<div class="jumbotron-header">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>Professional Painting Services</h1>
                <p>We are passionate about painting with quality and integrity</p>
                <button routerLink="/contact">Request an online quote</button>
                <h2><b>Or Call: </b><a href="tel:+15173318528‬">(517) 331-8528‬</a></h2>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-our-why">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="card-one">
                    <h2>Quality First</h2>
                    <h1>Why Choose Us</h1>
                    <div class="hr"></div>
                    <p>When you hire us to do a job, we recognize the fact that we work for you. We are your employee and it is our goal to leave you smiling. Your project is our passion and we only offer quality we’re willing to sign our name to.</p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <a routerLink="/services">
                    <div class="card-two">
                        <img src="/assets/img/photos/paint.png" alt="paint roller icon">
                        <h1>Top Notch Painters who care</h1>
                        <p>We are a company highly committed to providing outstanding quality service on every project.</p>
                        <button>View our work</button>
                    </div>
                </a>
                <a routerLink="/services">
                    <div class="card-two">
                        <img src="/assets/img/photos/sketchbook.png" alt="paint roller icon">
                        <h1>Color & Material Guidance</h1>
                        <p>Not quite sure what you’re looking for? We’re happy to help you find the look that’s right for you.</p>
                        <button>Request Help</button>
                    </div>
                </a>
                <a routerLink="/services">
                    <div class="card-two">
                        <img src="/assets/img/photos/swatch.png" alt="paint roller icon">
                        <h1>Free Quote & Consultations</h1>
                        <p>Schedule your courtesy consultation to start bringing your vision into reality.</p>
                        <button>Make an Appointment</button>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-info">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 card-one">
                <h1>Commercial & Residental</h1>
                <div class="hr"></div>
                <p>Whether it’s business or personal, we offer high quality painting that’s worth every dollar spent.</p>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 card-two">
                <h1>Interior & Exterior</h1>
                <div class="hr"></div>
                <p>We paint from the comfort of the indoors as well as brave the elements. Whatever you need painted, we’ve got you covered.</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-work">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h2>Check it out</h2>
                <h1>Some of our Work</h1>
                <div class="hr"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                <a (click)="imageOne()">
                    <div class="contain img-one">
                        <img src="/assets/img/photos/portfolio-one.jpg" alt="painted exterior">
                        <i class="fas fa-images"></i>
                        <div class="overlay">
                            <i class="fas fa-images"></i>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                <a (click)="imageTwo()">
                    <div class="contain img-two">
                        <img src="/assets/img/photos/portfolio-two.jpg" alt="painted exterior">
                        <i class="fas fa-images"></i>
                        <div class="overlay">
                            <i class="fas fa-images"></i>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                <a (click)="imageThree()">
                    <div class="contain img-three">
                        <img src="/assets/img/photos/portfolio-three.jpg" alt="painted exterior">
                        <i class="fas fa-images"></i>
                        <div class="overlay">
                            <i class="fas fa-images"></i>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>

<app-newsletter></app-newsletter>

<app-quote></app-quote>

<app-footer></app-footer>