<app-nav></app-nav>

<div class="jumbotron-content">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <h1>404</h1>
                <h2>Page not found</h2>
                <button>Return Home</button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>