<a (click)="toTopButton()" id="ToTopButton"><i class="fa fa-angle-up"></i></a>

<div class="jumbotron-footer-info">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <img src="/assets/img/logos/logo-white.png" alt="Mauzé Painting Logo">
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <h1>Contact Us</h1>
                <p>Tel: <a href="tel:+15173318528‬">(517) 331-8528‬</a></p>
                <p>E-mail: <a href="mailto:info@mauzepainting.com">info@mauzepainting.com</a></p>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <h1>Your Project. Our Passion.</h1>
                <div class="social-media">
                    <a matTooltip="Coming Soon!"><i class="fab fa-facebook-f"></i></a>
                    <a target="_blank" href="https://www.instagram.com/mauzepaintingllc/"><i class="fab fa-instagram"></i></a>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <h1>Our Values</h1>
                <p>“So whatever you wish that others would do to you, do also to them…” Matthew 7:12</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <p>© {{ year }} {{ appTitle }}. All rights reserved.</p>
                <p>Design by: © <a target="_blank" href="https://www.thegreenedgellc.com" target="_blank"  style="color: #1ABC9C;">The Green Edge LLC</a></p> 
            </div>
        </div>
    </div>
</div>
