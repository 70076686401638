import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  // SET CONTACT FORM GROUP
  contactForm: any;
  loading = false;
  success = false;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private fun: AngularFireFunctions,
  ) { }

  ngOnInit(): void {
    // CONTACT FORM VALIDATORS
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
  }

  // GET CONTACT FORM INPUTS
  get name() {
    return this.contactForm.get('name');
  }
  get email() {
    return this.contactForm.get('email');
  }
  get message() {
    return this.contactForm.get('message');
  }

  // SUBMIT FORM VIA EMAIL
  onSubmit(): void {
    this.loading = true;
    const callable = this.fun.httpsCallable('sendContactEmail');
    callable({
      // to: 'contact@thegreenedgellc.com',
      to: 'info@mauzepainting.com',
      from: 'no-reply@mauzepainting.com',
      domain: 'mauzepainting.com',
      clientName: 'Jeanel',
      fname: this.name.value,
      email: this.email.value,
      phone: '',
      message: this.message.value
    }).subscribe(
      err => {
        console.log(err);
        this.loading = false;
      }, () => {
        this.loading = false;
        this.success = true;
      }
    );
    this.success = true;
  }

  // SENT MESSAGE ALERT
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
