<div class="jumbotron-newsletter">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <h2>Newsletter</h2>
                <h1>Subscribe to our email list for 10% Off your first service</h1>
                <div class="hr"></div>
                <!-- <p>Fill out the form to schedule your courtesy consultation.</p> -->
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <br>
                <input type="text" placeholder="Name">
                <input type="email" placeholder="Email">
                <button matTooltip="Coming Soon!">Subscribe Now</button>
            </div>
        </div>
    </div>
</div>
