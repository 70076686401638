import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// ANGULAR FIREBASE
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from '../environments/environment';

// Custom Material Module
import { CustomMaterialModule } from './shared/material';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { FaqComponent } from './faq/faq.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { FourZeroFourComponent } from './four-zero-four/four-zero-four.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { QuoteComponent } from './quote/quote.component';
import { DialogImagesComponent } from './dialog-images/dialog-images.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogEmployeesComponent } from './dialog-employees/dialog-employees.component';
import { EmployeeFormComponent } from './employee-form/employee-form.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    FooterComponent,
    FaqComponent,
    AboutComponent,
    ServicesComponent,
    ContactComponent,
    FourZeroFourComponent,
    NewsletterComponent,
    QuoteComponent,
    DialogImagesComponent,
    DialogEmployeesComponent,
    EmployeeFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CustomMaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
