// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCgWHVmJWnldmfhFeeDthdtVDH_4vmOuLs",
    authDomain: "client-website-hub.firebaseapp.com",
    projectId: "client-website-hub",
    storageBucket: "client-website-hub.appspot.com",
    messagingSenderId: "315298980422",
    appId: "1:315298980422:web:173bdab1841d5e65502047",
    measurementId: "G-FE3ZBRNND8"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
