import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.scss']
})
export class EmployeeFormComponent implements OnInit {
  // SET CONTACT FORM GROUP
  contactForm: any;
  loading = false;
  success = false;

  property: string = 'The Residences'
  propertyResidence: boolean = true;
  propertyStonehouse: boolean = false;
  propertyStAnnesLoft: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private fun: AngularFireFunctions,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    // CONTACT FORM VALIDATORS
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      unit: ['', Validators.required],
      date: ['', Validators.required],
      size: ['', Validators.required],
      doors: ['', Validators.required],
      notes: ['', Validators.required]
    });
  }

  // SET CORRESPONDING RESIDENCE FORM
  setResidenceForm() {
    this.property = 'The Residences';
    this.propertyResidence = true;
    this.propertyStonehouse = false;
    this.propertyStAnnesLoft = false;
  }
  setStonehouseForm() {
    this.property = 'Stonehouse Village';
    this.propertyResidence = false;
    this.propertyStonehouse = true;
    this.propertyStAnnesLoft = false;
  }
  setStAnnesLoftForm() {
    this.property = 'St. Anne\'s Loft';
    this.propertyResidence = false;
    this.propertyStonehouse = false;
    this.propertyStAnnesLoft = true;
  }
  // GET CONTACT FORM INPUTS
  get name() {
    return this.contactForm.get('name');
  }
  get unit() {
    return this.contactForm.get('unit');
  }
  get date() {
    return this.contactForm.get('date');
  }
  get size() {
    return this.contactForm.get('size');
  }
  get doors() {
    return this.contactForm.get('doors');
  }
  get notes() {
    return this.contactForm.get('notes');
  }

  // SUBMIT FORM VIA EMAIL
  onSubmit(): void {
    this.loading = true;
    const callable = this.fun.httpsCallable('sendMauzePaintingForm');
    callable({
      // to: 'contact@thegreenedgellc.com',
      to: 'info@mauzepainting.com',
      from: 'no-reply@mauzepainting.com',
      name: this.name.value,
      property: this.property,
      unit: this.unit.value,
      date: this.date.value,
      size: this.size.value,
      doors: this.doors.value,
      notes: this.notes.value
    }).subscribe(
      err => {
        console.log(err);
        this.loading = false;
      }, () => {
        this.loading = false;
        this.success = true;
      }
    );
    this.success = true;
  }

  // SENT MESSAGE ALERT
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}