<app-nav></app-nav>

<div class="jumbotron-header">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>Painting Services</h1>
                <p>Commercial | Residential</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-content">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>Beautify and protect your property with our services</h1>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                <img class="mobile-img" src="/assets/img/photos/commercial.jpg" alt="commercial business painted">
                <h2>Commercial & Business Painting Services</h2>
                <div class="hr"></div>
                <p>Are you a business owner or property management manager in need of a painting company for an upcoming project? Look no further! Whether you have a new construction project, a remodel you’re working on, or just need to bring a refreshing look to your office space, warehouse, or other properties, we have the experience and expertise you need.</p>
                <h3>Interior & Exterior Painting</h3>
                <h3>Maintenance Painting</h3>
                <h3>Pressure Washing</h3>
                <h3>Wall Covering Removal</h3>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                <img class="desktop-img" src="/assets/img/photos/commercial.jpg" alt="commercial business painted">
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <mat-divider></mat-divider>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                <img class="mobile-img" src="/assets/img/photos/interior.jpg" alt="residential interior painted">
                <h2>Residential interior services</h2>
                <div class="hr"></div>
                <p>Express yourself to the fullest! Stay simple or go bold. There is no limit to color possibilities. No matter how you choose to decorate your home, the mood and feel you want your space to embody is not complete without the perfect paint job.</p>
                <h3>Caulking</h3>
                <h3>Wood Staining & Painting</h3>
                <h3>Ceiling & Walls</h3>
                <h3>Painting of Drywall & Plaster</h3>
                <h3>Patching & Repairing of Drywall</h3>
                <h3>Accent Walls</h3>
                <h3>Baseboards, Crown & other trim moulding</h3>
                <h3>Cabinet Painting</h3>
                <h3>Cabinet Refreshing</h3>
                <h3>Decorative Painting</h3>
                <h3>Touch Up’s</h3>
                <h3>Color Consulting</h3>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                <img class="desktop-img" src="/assets/img/photos/interior.jpg" alt="residential interior painted">
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <mat-divider></mat-divider>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                <img class="mobile-img" src="/assets/img/photos/exterior.jpg" alt="residential exterior painted">
                <h2>Residential exterior services</h2>
                <div class="hr"></div>
                <p>No better way to add value and protect your home than with a refreshing paint job on the exterior of your home.</p>
                <h3>Caulking</h3>
                <h3>Wood Painting & Staining</h3>
                <h3>Cleaning & Pressure Washing</h3>
                <h3>Siding Painting</h3>
                <h3>Stucco Painting</h3>
                <h3>Waterproof Coatings</h3>
                <h3>Minor Repairs on Wood</h3>
                <h3>Baseboards, Crown & other Trim</h3>
                <h3>Color Consulting</h3>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                <img class="desktop-img" src="/assets/img/photos/exterior.jpg" alt="residential exterior painted">
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-clients">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>Types of Industries We've Served</h1>
                <div class="hr"></div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12">
                <p>Offices</p>
                <p>Property Management</p>
                <p>Apartments</p>
                <p>Churches</p>
                <p>Plazas</p>
                <p>Retail</p>
                <p>Malls</p>
            </div>
            <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12">
                <p>Store Fronts</p>
                <p>Restaurants</p>
                <p>Business Centerss & Industrial Parks</p>
                <p>Hospitals</p>
                <p>Nursing Homes</p>
                <p>Home Owners & Condo Associations</p>
                <p>Food & Agriculture Facilities</p>
            </div>
        </div>
    </div>
</div>

<app-newsletter></app-newsletter>

<app-quote></app-quote>

<app-footer></app-footer>