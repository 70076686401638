<app-nav></app-nav>

<div class="jumbotron-header">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>Employee Painting Forms</h1>
                <p>Please complete the following form in full:</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-content">
    <div class="container">
        <form [formGroup]="contactForm" [hidden]="success" (ngSubmit)="onSubmit()">
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <h1>Select a property:</h1>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <div class="menu_chip" (click)="setResidenceForm()" [ngClass]="{'active': this.propertyResidence === true}">The Residence</div>
                    <div class="menu_chip" (click)="setStonehouseForm()" [ngClass]="{'active': this.propertyStonehouse === true}">Stonehouse Village</div>
                    <div class="menu_chip" (click)="setStAnnesLoftForm()" [ngClass]="{'active': this.propertyStAnnesLoft === true}">St. Anne's Loft</div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Worked By:</label>
                    <input [ngClass]="{'error': name.invalid && name.touched }" type="text" placeholder="Your Name" formControlName="name" required>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Unit Number:</label>
                    <input [ngClass]="{'error': unit.invalid && unit.touched }" type="text" placeholder="Unit Number" formControlName="unit" required>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Completed Date:</label>
                    <input [ngClass]="{'error': date.invalid && date.touched }" type="date" placeholder="Date" formControlName="date" required>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Room Size:</label>
                    <select [ngClass]="{'error': size.invalid && size.touched }" type="text" formControlName="size" required>
                        <option value="none" selected=true disabled>Select an Option</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="2 XL" *ngIf="this.propertyStonehouse === true">2 XL</option>
                        <option value="3" *ngIf="this.propertyResidence === true">3</option>
                    </select>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <label>Number of Doors</label>
                    <select [ngClass]="{'error': doors.invalid && doors.touched }" type="text" placeholder="Please select the number of doors painted" formControlName="doors" required>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                    </select>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <label>Additional Notes:</label>
                    <textarea [ngClass]="{'error': notes.invalid && notes.touched }" placeholder="Please provide more here ..." formControlName="notes" required></textarea>
                    <button [disabled]="contactForm.invalid" color="primary" (click)="openSnackBar('Sending message ...','')">Submit Form</button>
                </div>
            </div>
        </form>
        <div *ngIf="success">
            <h1 style="text-align: center"><i class="far fa-paper-plane"></i>Form Completed & Sent!</h1>
            <p>Thank you for your time and efforts.</p>
        </div>
    </div>
</div>

<app-footer></app-footer>
