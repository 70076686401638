<div class="navbar-info">
    <div class="container">
        <a class="social-media-icon" target="_blank" href="https://www.instagram.com/mauzepaintingllc/"><i class="fab fa-instagram"></i></a>
        <a matTooltip="Coming Soon!" class="social-media-icon"><i class="fab fa-facebook-f"></i></a>
        <a target="_blank" href="mailto:info@mauzepainting.com"><i class="fas fa-envelope"></i>info@mauzepainting.com</a>
        <a href="tel:15173318528‬"><i class="fas fa-phone-alt"></i>(517) 331-8528‬</a>
    </div>
</div>

<div class="navbar-desktop" id="navbar-desktop">
    <div class="container">
        <mat-toolbar>
            <mat-toolbar-row>
                <a routerLink="/"><img src="/assets/img/logos/logo-blue.png"></a>
                <span class="spacer"></span>
                <button routerLink="/" [routerLinkActive]="'navbar-desktop-active'" [routerLinkActiveOptions]="{ exact: true }">Home</button>
                <button routerLink="/about" [routerLinkActive]="'navbar-desktop-active'">About</button>
                <button routerLink="/services" [routerLinkActive]="'navbar-desktop-active'">Services</button>
                <button routerLink="/contact" [routerLinkActive]="'navbar-desktop-active'">Contact</button>
                <button (click)="dialogEmployees()">Employees</button>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
</div>

<div class="navbar-mobile">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <a routerLink="/"><img src="/assets/img/logos/logo-blue.png"></a>
                <button (click)="toggleSideNav()"><i class="material-icons">menu</i></button>
            </div>
        </div>
    </div>
</div>

<div class="sideNav" *ngIf="this.toggleMode === true">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <button class="close-button" (click)="toggleSideNav()"><i class="material-icons">close</i></button>
                <img src="/assets/img/logos/logo-white.png">
                <mat-divider></mat-divider>
                <button (click)="toggleSideNav()" routerLink="/">Home</button>
                <mat-divider></mat-divider>
                <button (click)="toggleSideNav()" routerLink="/about">About</button>
                <mat-divider></mat-divider>
                <button (click)="toggleSideNav()" routerLink="/services">Services</button>
                <mat-divider></mat-divider>
                <button (click)="toggleSideNav()" routerLink="/contact">Contact</button>
                <mat-divider></mat-divider>
                <button (click)="toggleSideNav(); dialogEmployees();">Employees</button>
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
</div>