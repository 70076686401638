<app-nav></app-nav>

<div class="jumbotron-header">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>Contact Us</h1>
                <p>We'd love to hear from you!</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-info">
    <div class="container">
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <h1>Give Us a Call</h1>
                <a href="tel:+15173318528">(517) 331-8528</a>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <h1>Send Us an Email</h1>
                <a href="mailto:info@mauzepainting.com">info@mauzepainting.com</a>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <h1>Our Business Hours</h1>
                <p><b>Monday - Friday:</b></p>
                <p>9:00am - 4:00pm</p>
                <p>Service hours vary by<br>appointments scheduled.</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-contact">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="card-one">
                    <h2>Contact Us</h2>
                    <h1>Email Us and Get a Quote</h1>
                    <div class="hr"></div>
                    <p>Fill out the form to schedule your courtesy consultation.</p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="card-two">
                    <!-- <input type="text" placeholder="Name">
                    <input type="tel" placeholder="Phone">
                    <input type="email" placeholder="Email">
                    <textarea placeholder="Message"></textarea>
                    <button>Send Request</button> -->
                    <form [formGroup]="contactForm" [hidden]="success" (ngSubmit)="onSubmit()">
                        <mat-form-field>
                            <input matInput type="text" placeholder="Your Name" formControlName="name" required>
                            <mat-error *ngIf="name.invalid && name.touched">
                                Please provide your name.
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="email" placeholder="Email" formControlName="email" required>
                            <mat-error *ngIf="email.invalid && email.touched">
                                Please provide a valid email.
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <textarea matInput placeholder="Message" formControlName="message" required></textarea>
                            <mat-error *ngIf="message.invalid && message.touched">
                                Please provide us with more information here.
                            </mat-error>
                        </mat-form-field>
                        <button mat-button [disabled]="contactForm.invalid" color="primary" (click)="openSnackBar('Sending message ...','')">Send Request</button>
                    </form>
                    <div *ngIf="success">
                        <h1><i class="far fa-paper-plane"></i> Message Sent!</h1>
                        <p>Thank you for your message, <br> We look forward to working with you.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-faq></app-faq>

<app-footer></app-footer>