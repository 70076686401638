<div class="jumbotron-content">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>Frequently Asked Questions</h1>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <mat-accordion>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>How can I reach you?</mat-panel-title>
                            <!-- <mat-panel-description>Currently I am {{ panelOpenState ? 'open' : 'closed'}}</mat-panel-description> -->
                        </mat-expansion-panel-header>
                        <p>For all of our contact info, check out our <a routerLink="/contact">contact page</a> or send us a message on one of our social media accounts. Scroll to the bottom of any page to find a link to our Facebook and Instagram.</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Do you charge for an estimate?</mat-panel-title>
                            <!-- <mat-panel-description>Currently I am {{ panelOpenState ? 'open' : 'closed'}}</mat-panel-description> -->
                        </mat-expansion-panel-header>
                        <p>No, there is no charge for an estimate.</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Are you insured?</mat-panel-title>
                            <!-- <mat-panel-description>Currently I am {{ panelOpenState ? 'open' : 'closed'}}</mat-panel-description> -->
                        </mat-expansion-panel-header>
                        <p>Yes, we are insured by the United States Liability Company with $2 million liability coverage.</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Do you only paint houses?</mat-panel-title>
                            <!-- <mat-panel-description>Currently I am {{ panelOpenState ? 'open' : 'closed'}}</mat-panel-description> -->
                        </mat-expansion-panel-header>
                        <p>No, we do both residential and commercial work.</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Do you work with real estate or property management companies?</mat-panel-title>
                            <!-- <mat-panel-description>Currently I am {{ panelOpenState ? 'open' : 'closed'}}</mat-panel-description> -->
                        </mat-expansion-panel-header>
                        <p>Yes, request a reference for more info.</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Do you paint both exteriors and interiors?</mat-panel-title>
                            <!-- <mat-panel-description>Currently I am {{ panelOpenState ? 'open' : 'closed'}}</mat-panel-description> -->
                        </mat-expansion-panel-header>
                        <p>Yes, we are experienced with painting both the exterior and interior of residential housing as well as commercial/business facilities.</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>What type of paint do you use?</mat-panel-title>
                            <!-- <mat-panel-description>Currently I am {{ panelOpenState ? 'open' : 'closed'}}</mat-panel-description> -->
                        </mat-expansion-panel-header>
                        <p>The exact type of paint that is used is evaluated at the time of the estimate and will vary from one job to another.</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>What type of painting brands are used?</mat-panel-title>
                            <!-- <mat-panel-description>Currently I am {{ panelOpenState ? 'open' : 'closed'}}</mat-panel-description> -->
                        </mat-expansion-panel-header>
                        <p>90% of products used are from O'Leary or Sherwin-Williams.</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Do you have any references?</mat-panel-title>
                            <!-- <mat-panel-description>Currently I am {{ panelOpenState ? 'open' : 'closed'}}</mat-panel-description> -->
                        </mat-expansion-panel-header>
                        <p>Yes, references are available upon request.</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Do you have any examples of your work?</mat-panel-title>
                            <!-- <mat-panel-description>Currently I am {{ panelOpenState ? 'open' : 'closed'}}</mat-panel-description> -->
                        </mat-expansion-panel-header>
                        <p>Yes, check out all the work we do and images on our <a routerLink="/services">services page</a>.</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Do you only paint?</mat-panel-title>
                            <!-- <mat-panel-description>Currently I am {{ panelOpenState ? 'open' : 'closed'}}</mat-panel-description> -->
                        </mat-expansion-panel-header>
                        <p>No, we do paint and stain work as well as minor wall and surface repairs to areas we will be painting. Check out a full list of the services we offer on our services page.</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Can I leave my home while your painting crew works?</mat-panel-title>
                            <!-- <mat-panel-description>Currently I am {{ panelOpenState ? 'open' : 'closed'}}</mat-panel-description> -->
                        </mat-expansion-panel-header>
                        <p>Yes, most people we work for are not home while work is going on. While you are away our crew will be busy painting. We are licensed and fully insured so there is nothing to worry about.</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Can I be at home if I'm pregnant while you're painting?</mat-panel-title>
                            <!-- <mat-panel-description>Currently I am {{ panelOpenState ? 'open' : 'closed'}}</mat-panel-description> -->
                        </mat-expansion-panel-header>
                        <p>If you are home, we will not kick you out, however, it is HIGHLY recommended that you are not around during the paint application process due to the fumes in the air that you would breathe in. These fumes could potentially have negative effects on your unborn child.</p>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</div>