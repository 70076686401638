<div class="jumbotron-content">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 no-padding">
                <h1>Employee Painting Forms</h1>
                <div class="hr"></div>
                <p>Please enter the access code to enter the employee portal:</p>
                <form [formGroup]="accessForm">
                    <h6 *ngIf="errorCode === 'false'">Access code is incorrect. Please try again ...</h6>
                    <input [ngClass]="{'error': accessCode.invalid && accessCode.touched }" type="text" placeholder="Please enter the access code" formControlName="accessCode" required>
                </form>
                <button (click)="onSubmit();">Submit</button>
            </div>
        </div>
    </div>
</div>